// Overrides
[ui-theme],
body [ui-theme="default"] {
    --default-height-inputs: 4rem;
    --default-font-size: 1.4rem;
}

body {
    background-color: var(--default-surface-color) !important;
}

// Normal
html {
    &,
    ui-body {
        line-height: 1.4rem !important;

        .ui-loader {
            position: fixed;
        }
    }

    // Angular CDK is messing with our setup (we should simplify)
    &.cdk-global-scrollblock {
        overflow: auto;
    }
}

a {
    &:link,
    &:visited {
        text-decoration: none;
        color: var(--default-secondary-text-color);
        cursor: pointer;
    }

    &:link:active,
    &:visited:active {
        color: var(--default-secondary-text-color);
    }
}

.app-container {
    margin: 0 auto;
    position: relative;

    @media screen and (min-width: 1088px) {
        & {
            max-width: 960px;
            width: 960px;
        }
    }

    @media screen and (min-width: 1280px) {
        & {
            max-width: 1152px;
            width: 1152px;
        }
    }

    @media screen and (min-width: 1472px) {
        & {
            max-width: 1344px;
            width: 1344px;
        }
    }
}

.column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0.75rem;

    @media screen and (max-width: 768px) {
        & {
            display: inline-block;
            max-width: 100%;
            width: 100%;
        }

        &.is-full-small {
            flex: none;
            width: 100%;
        }

        &.is-five {
            width: unset;
            display: flex;
            justify-content: center;
        }

        &.is-six,
        &.is-half {
            flex: none;
            width: 50%;
        }

        &.is-seven {
            flex: none;
            width: 58.333333%;
        }
    }

    @media screen and (min-width: 769px) {
        & {
            max-width: 668px;
            width: 668px;
        }
    }

    @media screen and (min-width: 1088px) {
        & {
            max-width: 960px;
            width: 960px;
        }
    }

    @media screen and (min-width: 1472px) {
        & {
            max-width: 1344px;
            width: 1344px;
        }
    }

    @media screen and (min-width: 1280px) {
        & {
            max-width: 1152px;
            width: 1152px;
        }
    }

    @media screen and (min-width: 769px) {
        &.is-one {
            flex: none;
            width: 8.333333%;
        }

        &.is-two {
            flex: none;
            width: 16.66666%;
        }

        &.is-three {
            flex: none;
            width: 25%;
        }

        &.is-four {
            flex: none;
            width: 33.33333%;
        }

        &.is-five {
            flex: none;
            width: 41.66666%;
        }

        &.is-six,
        &.is-half {
            flex: none;
            width: 50%;
        }

        &.is-seven {
            flex: none;
            width: 58.333333%;
        }
    }
}

.columns {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-top: -0.75rem;

    &:last-child {
        margin-bottom: -0.75rem;
    }

    &:not(:last-child) {
        // margin-bottom: calc(1.5rem - 0.75rem);
    }

    &.columns-centered {
        justify-content: center;
    }

    &.columns-vcentered {
        align-items: center;
    }

    @media screen and (min-width: 769px) {
        & {
            display: flex;
        }
    }
}

.section {
    padding: 3rem 1.5rem;
}

.field:not(:last-child) {
    margin-bottom: 2rem;
}

.label {
    margin-bottom: 1.7rem;
    display: block;
}

.text-centered {
    text-align: center !important;
}

.undertext {
    padding-top: 5rem;

    ui-icon {
        position: relative;
        bottom: 0.2rem;
    }
}

.small-arrow {
    font-size: 0.9rem !important;
    vertical-align: middle;
}

.small-padding {
    padding-top: 10px;
}

.fit-content-class {
    padding: 2rem !important;
}

.error__message {
    color: var(--default-alert-color);
    padding-top: 0.8rem;
    font-weight: var(--default-font-weight-bold);
    font-size: 1.2rem;
}
